import useMsal from './useMsal';

const graphRequest = {
  scopes: ['User.Read'],
};

export default async function useCallApi(url, body = undefined) {
  const headers = new Headers();
  const { getAccessToken } = useMsal();
  const token = await getAccessToken(graphRequest);
  const bearer = `Bearer ${token.accessToken}`;
  headers.append('Authorization', bearer);
  headers.append('accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const options = {
    method: body ? 'post' : 'get',
    headers: headers,
    body: JSON.stringify(body),
  };
  const response = await fetch(url, options).catch((error) => console.log(error));
  return response;
}
