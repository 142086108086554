import useCallApi from './useCallApi';
import { ref } from '@vue/reactivity';
import { computed } from '@vue/runtime-core';
import router from '@/router/index';

const apiUrl = process.env.VUE_APP_API_CADIS;
const compare = ref();
const duplicates = ref();
const onlyDuplicates = ref(false);
const sentForm = ref();
const toImport = ref([]);
const total = ref(0);
const loading = ref(false);
const leadthemes = ref([]);
const user = ref({});
const users = ref();
const teams = ref();
const salesRegions = ref();
const history = ref();

export default function useCadis() {
  const cadisInit = () => {
    getUser();
    getUsers();
    getTeams();
    fetchHistory();
    getSalesRegion();
  };
  const getUser = async () => {
    await useCallApi(apiUrl + '/user')
      .then((response) => {
        user.value.ok = false;
        return response.json();
      })
      .then((response) => {
        if (response.status === 200) {
          user.value = response;
          user.value.ok = true;
        } else {
          user.value.ok = false;
        }
      });
  };
  const checkUser = (j = 20, i = 1) => {
    if (user.value.ok || user.value.ok === false || i === j) {
      if (!user.value.single) router.push('/');
      return;
    }
    setTimeout(function () {
      i++;
      if (i < j) {
        checkUser();
      }
    }, 500);
  };
  const importLeads = async (form) => {
    loading.value = true;
    leadthemes.value.push(form.leadtheme);
    compare.value = undefined;
    useCallApi(apiUrl + '/vtiger/import', form);
    loading.value = false;
  };
  const importUniquekey = async (form) => {
    await useCallApi(apiUrl + '/vtiger/import/uniquekey', form);
  };
  const fetchComparison = async (form) => {
    loading.value = true;
    compare.value = await useCallApi(apiUrl + '/vtiger/comparison', form)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        total.value = response.total;
        return response.data;
      });
    loading.value = false;
  };
  const fetchHistory = async () => {
    history.value = await useCallApi(apiUrl + '/vtiger/history').then((response) => {
      return response.json();
    });
  };
  const getTeams = async () => {
    teams.value = await useCallApi(apiUrl + '/vtiger/teams').then((response) => {
      return response.json();
    });
  };
  const getUsers = async () => {
    users.value = await useCallApi(apiUrl + '/vtiger/users').then((response) => {
      return response.json();
    });
  };
  const getSalesRegion = async () => {
    salesRegions.value = await useCallApi(apiUrl + '/regions').then((response) => {
      return response.json();
    });
  };
  return {
    loading: computed(() => loading.value),
    fetchComparison,
    importLeads,
    importUniquekey,
    history,
    cadisInit,
    compare,
    duplicates,
    onlyDuplicates,
    sentForm,
    toImport,
    leadthemes,
    total,
    checkUser,
    user,
    users,
    teams,
    salesRegions,
  };
}
