import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/tailwind.css';

const app = createApp(App);

app.config.globalProperties.$msal = {};

app.use(router).mount('#app');
