<template>
  <Disclosure as="nav" class="bg-gray-800">
    <div class="px-2 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <div class="flex items-center justify-center flex-1 sm:items-stretch sm:justify-start">
          <div class="flex items-center flex-shrink-0 pr-16">CADIS</div>
          <div class="hidden sm:block sm:ml-6">
            <div class="flex space-x-4">
              <router-link
                v-for="route in routes"
                :key="route.path"
                class="px-3 py-2 text-lg font-medium rounded-md"
                active-class="bg-gray-900"
                :to="route.path"
                >{{ route.name }}</router-link
              >
            </div>
          </div>
        </div>
        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <!-- Profile dropdown -->
          <Menu v-if="account" as="div" class="relative ml-3">
            <div class="flex items-center">
              <div class="m-4 text-xl">
                {{ [user.first_name, user.last_name].join(' ') }}
              </div>
              <MenuButton
                class="
                  flex
                  text-sm
                  bg-gray-800
                  rounded-full
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white
                "
              >
                <span class="sr-only">Open user menu</span>
                <img class="w-12 h-12 rounded-full" :src="photoUrl" alt="" />
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems
                class="
                  absolute
                  right-0
                  w-48
                  py-1
                  mt-2
                  origin-top-right
                  bg-white
                  rounded-md
                  shadow-lg
                  ring-1 ring-black ring-opacity-5
                  focus:outline-none
                "
              >
                <MenuItem v-slot="{ active }">
                  <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']" @click="signOut"
                    >Sign out</a
                  >
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
          <button v-else @click="signIn">Einloggen</button>
        </div>
      </div>
    </div>
  </Disclosure>
</template>

<script>
import { Disclosure, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { useRouter } from 'vue-router';
import useMsal from '@/composables/useMsal';
import useGraph from '@/composables/useGraph';
import useCadis from '@/composables/useCadis';

export default {
  components: {
    Disclosure,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
  setup() {
    const router = useRouter();
    const { signIn, signOut, account, handleResponse } = useMsal();
    const { photoUrl } = useGraph();
    const { user } = useCadis();
    const routes = router.options.routes.filter((value) => !value.name.includes(['not-found']));

    handleResponse();

    return {
      routes,
      signIn,
      signOut,
      account,
      photoUrl,
      user,
    };
  },
};
</script>
