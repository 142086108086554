import useCallApi from './useCallApi';
import { ref } from 'vue';

const apiUrl = 'https://graph.microsoft.com/beta';
const photoUrl = ref(undefined);

export default function () {
  function graphInit() {
    getPhoto();
  }
  async function getPhoto() {
    return useCallApi(apiUrl + '/me/photos/240x240/$value')
      .then((response) => {
        return response.blob();
      })
      .then((myBlob) => {
        photoUrl.value = URL.createObjectURL(myBlob);
      });
  }

  return {
    graphInit,
    photoUrl,
  };
}
