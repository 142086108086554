import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import { ref } from 'vue';
import useGraph from '@/composables/useGraph';
import useCadis from './useCadis';

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_CLIENT_ID,
    redirectUri: process.env.BASE_URL,
    // client_secret: process.env.VUE_APP_CLIENT_SECRET,
    // authority: 'https://login.microsoftonline.com/ca735529-5a14-4a0c-b3f2-6c37b918229e',
  },
  cache: {
    cacheLocation: process.env.VUE_APP_CACHE_LOCATION,
  },
};
const loginRequest = {
  scopes: [],
};

const myMsal = new PublicClientApplication(msalConfig);
const account = ref(undefined);
const { graphInit } = useGraph();
const { cadisInit } = useCadis();

export default function () {
  function getAccessToken(request) {
    request.account = account.value;
    return myMsal.acquireTokenSilent(request).catch((error) => {
      console.warn('silent token acquisition fails. acquiring token using redirect');
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return myMsal
          .acquireTokenRedirect(request)
          .then((tokenResponse) => {
            console.log(tokenResponse);

            return tokenResponse.accessToken;
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.warn(error);
      }
    });
  }
  function handleResponse() {
    myMsal
      .handleRedirectPromise()
      .then((result) => {
        if (result) {
          history.replaceState({}, document.title, '.');
          setTimeout(function () {
            setAccount();
          }, 1000);
        } else {
          setAccount();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function signIn() {
    myMsal.loginRedirect(loginRequest);
  }
  async function signOut() {
    const logoutRequest = {
      account: account.value,
    };
    account.value = undefined;
    myMsal.logout(logoutRequest);
  }
  function setAccount() {
    if (myMsal.getAllAccounts().length === 1) {
      account.value = myMsal.getAllAccounts()[0];
      graphInit();
      cadisInit();
    }
  }
  return {
    getAccessToken,
    signIn,
    handleResponse,
    setAccount,
    signOut,
    account,
  };
}
